import React from "react";
import "./index.css";
import VisionImg from "../../../assets/VisionImg.png";

const Vision = () => {
  return (
    <div className="visionWrapper">
      <div className="mvImgWrapper">
        <img className="mvImg" src={VisionImg} alt="/" />
      </div>
      <div className="homeHeading1">
        Our{" "}
        <span className="homeHeadingSpan" style={{ color: "#DFA114" }}>
          Vision
        </span>
      </div>
      <div className="missionInfo">
        At OpGuru, our vision is to empower human potential to transform
        humanity and be the guiding light for global educational aspirations. We
        envision a world where every student's educational journey is marked by
        clarity, empowerment, and fulfillment. Our commitment extends beyond
        mere guidance; it is a steadfast dedication to realizing the dreams of
        students worldwide. With unwavering focus and compassion, we aim to
        illuminate the path to educational excellence, inspiring generations to
        achieve their fullest potential and shape a brighter future for all.
      </div>
    </div>
  );
};

export default Vision;
